@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif !important;
  background-color: white;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {

}

.menu {
  color:white !important;
  padding-top: 6px;
}

.title {
  margin: 6px 0px 0px 10px !important;
}

.footer {
  font-size: 12px;
  margin: 50px 0 auto;
}


@media(max-width: 480px) {
  .container {
    margin: 0 10%;
  }

  .title {
    display: none;
  }
}