.container {
  display: flex;
  justify-content: center;
  width: 85%;
}

@media(max-width: 480px) {
  .container{
    width: 100%;
  }
}