@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap);
.cards_container__2yxBC {
  margin: 50px 0;
}

.cards_card__11EsN {
  margin: 3px 2% !important;
}

.cards_infected__3z_m0 {
  border-bottom: 10px solid rgba(0, 0, 255, 0.5);
}

.cards_recovered__1C63Y {
  border-bottom: 10px solid rgba(0, 255, 0, 0.5);}

.cards_deaths__XZmZl {
  border-bottom: 10px solid rgba(255, 0, 0, 0.5);
}

@media(max-width: 480px) {
  .cards_card__11EsN {
    margin: 3% 0 !important;
  }
}
.Chart_container__3xsTk {
  display: flex;
  justify-content: center;
  width: 85%;
}

@media(max-width: 480px) {
  .Chart_container__3xsTk{
    width: 100%;
  }
}
.CountryPicker_formControl__3Y7yb {
  width: 30%;
  margin-bottom: 30px !important;
}
body {
  font-family: 'Poppins', sans-serif !important;
  background-color: white;
}

.App_container__1T3S1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.App_logo__2aWG3 {

}

.App_menu__K_aUC {
  color:white !important;
  padding-top: 6px;
}

.App_title__1dE9Z {
  margin: 6px 0px 0px 10px !important;
}

.App_footer__1Fjds {
  font-size: 12px;
  margin: 50px 0 auto;
}


@media(max-width: 480px) {
  .App_container__1T3S1 {
    margin: 0 10%;
  }

  .App_title__1dE9Z {
    display: none;
  }
}
